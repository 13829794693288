import Logo from 'assets/images/logo.png';

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  return (
    <>
      <img style={{width:'170px',height:"74.08px"}} src={Logo} alt="Logo" />
    </>
  );
};

export default LogoMain;
