// assets
import { CreditCardOutlined, AuditOutlined } from '@ant-design/icons';

// type
import { EarningsIcon } from 'assets/images/icons/earnings';
import { NavItemType } from 'types/menu';

// icons
const icons = {
  CreditCardOutlined,
  AuditOutlined,
  EarningsIcon
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const finance: NavItemType = {
  id: 'user-managment',
  title: 'finance',
  type: 'group',
  permissionKey: ['VIEW_ACCOUNTING_LIST', 'LIST_PAST_EARNINGS'],
  children: [
    {
      id: 'accounting',
      title: 'accounting',
      type: 'item',
      permissionKey: ['VIEW_ACCOUNTING_LIST'],
      url: '/apps/finance/accounting/list',
      icon: icons.CreditCardOutlined
    },
    {
      id: 'earnings',
      title: 'earnings',
      type: 'collapse',
      permissionKey: ['LIST_PAST_EARNINGS'],
      icon: icons.EarningsIcon,
      children: [
        {
          id: 'past',
          title: 'past',
          type: 'item',
          permissionKey: ['LIST_PAST_EARNINGS'],
          url: '/apps/finance/earnings/past/list'
        }
      ]
    },
    {
      id: 'invoices',
      title: 'invoices',
      type: 'item',
      url: '/apps/finance/invoice',
      icon: icons.AuditOutlined
    }
  ]
};

export default finance;
