import { Role, UserProfile } from 'types/auth';
import { format } from 'date-fns';

// eslint-disable-next-line no-useless-escape
export const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

export const checkSellerSideMenu = (isSeller: boolean, disableSeller: boolean, onlySeller: boolean) => {
  return !(disableSeller && isSeller) && !(onlySeller && !isSeller);
};

export const getTranslation = (obj: any, lang: string = 'en', item: string, defaultValue?: string) => {
  return obj?.translations?.find((t: any) => t.locale === lang)?.[item] ?? obj?.[item] ?? defaultValue ?? '-';
};

export const checkPermissionMatrix = (userType: string, permissionKeys: string[], userPermissions: Role[]) => {
  return (
    permissionKeys.includes('ALL') ||
    userPermissions?.some((p) => permissionKeys.includes(p.code)) ||
    (permissionKeys.includes('ADMINS') && (userType === 'ADMIN' || userType === 'SUPER_ADMIN')) ||
    (permissionKeys.includes('SUPER') && userType === 'SUPER_ADMIN')
  );
};

export const formatPrice = (price: string): string => {
  const formattedPrice = parseFloat(price).toFixed(2);
  return `${formattedPrice}₺`;
};

export const formatDate = (dateString: string, dateFormat: string): string => {
  const date = new Date(dateString);
  return format(date, dateFormat);
};

export const removeHTMLTags = (str: string): string => {
  return str.replace(/<[^>]*>?/gm, '');
};

export const validateDate = (dateString: string) => {
  const parts = dateString?.split('-').map((p) => parseInt(p, 10));
  parts[1] -= 1;
  const d = new Date(parts[0], parts[1], parts[2]);
  return d.getMonth() === parts[1] && d.getDate() === parts[2] && d.getFullYear() === parts[0];
};

export const getRedirectionLink = (user: UserProfile) => {
  const dashboardPermissionFound = user.permissions?.find((perm) => perm.code.includes('LIST_DASHBOARD'));
  if (dashboardPermissionFound) return '/apps/dashboard';
  const firstListPermission = user.permissions?.find((perm) => perm.code.includes('VIEW') || perm.code.includes('LIST'));
  switch (firstListPermission?.code) {
    //USER-MANAGEMENT
    case 'LIST_CUSTOMERS':
    case 'ARCHIVE_CUSTOMERS':
      return '/apps/user-managment/customers/list';
    case 'LIST_SELLERS':
      return '/apps/user-managment/seller/list';
    //CATALOGUE
    case 'LIST_PRODUCTS':
    case 'ARCHIVE_PRODUCTS':
      return '/apps/catalogue/products/list';
    case 'LIST_REVIEWS':
    case 'ARCHIVE_REVIEWS':
      return '/apps/catalogue/product-feedback/reviews/list';
    case 'LIST_QUESTIONS':
    case 'ARCHIVE_QUESTIONS':
      return '/apps/catalogue/product-feedback/questions/list';
    case 'VIEW_OPTIONS_LIST':
    case 'VIEW_OPTION_ARCHIVE':
      return '/apps/catalogue/product-specs/options/list';
    case 'VIEW_ATTRIBUTES_LIST':
    case 'VIEW_ATTRIBUTES_ARCHIVE':
      return '/apps/catalogue/product-specs/attribute/list';
    case 'VIEW_ATTRIBUTE_GROUPS_LIST':
    case 'VIEW_ATTRIBUTE_GROUP_ARCHIVE':
      return '/apps/catalogue/product-specs/attribute-groups/list';
    case 'VIEW_CATEGORY_LIST':
    case 'VIEW_CATEGORY_ARCHIVE':
      return '/apps/catalogue/category/list';
    case 'VIEW_PRODUCT_LIBRARY_LIST':
    case 'VIEW_PRODUCT_REQUEST_ARCHIVE':
      return '/apps/catalogue/product-requests/list';
    case 'VIEW_BRANDS_LIST':
    case 'VIEW_BRAND_ARCHIVE':
      return '/apps/catalogue/brands/list';
    case 'LIST_LIBRARY_BULK_UPLOAD':
      return '/apps/catalogue/bulks/library-bulk-upload/list';
    case 'LIST_SELLER_BULK_ASSIGNED':
      return '/apps/catalogue/bulks/seller-bulk-assigned';
    case 'LIST_SELLER_BULK_UPDATE':
      return '/apps/catalogue/bulks/seller-bulk-update/list';
    //CONTENT
    case 'VIEW_MEDEX_SLIDERS_LIST':
    case 'VIEW_MEDEX_SLIDER_ARCHIVE':
      return '/apps/content/medex-slider/list';
    case 'VIEW_PRIVILEGES_LIST':
    case 'VIEW_PRIVILEGE_ARCHIVE':
      return '/apps/content/priviliges/list';
    case 'VIEW_BLOG_LIST':
    case 'VIEW_BLOG_ARCHIVE':
      return '/apps/content/blog/list';
    case 'VIEW_SELLER_FAQ_LIST':
    case 'VIEW_SELLER_FAQ_ARCHIVE':
      return '/apps/content/seller-faq/list';
    case 'VIEW_CUSTOMER_FAQ_LIST':
    case 'VIEW_CUSTOMER_FAQ_ARCHIVE':
      return '/apps/content/customer-faq/list';
    case 'VIEW_SELLER_EDUCATION_CENTER_LIST':
    case 'VIEW_SELLER_EDUCATION_CENTER_ARCHIVE':
      return '/apps/content/seller-education-center/list';
    //DASHBOARD
    case 'LIST_DASHBOARD_OVERVIEW':
    case 'LIST_DASHBOARD_FINANCE':
    case 'LIST_DASHBOARD_GROSS_MERCHANDISES_VAlUE':
    case 'LIST_DASHBOARD_AVERAGE_ORDER_VALUE':
    case 'LIST_DASHBOARD_ORDERS_STATUS':
    case 'LIST_DASHBOARD_PRODUCT_LIST':
    case 'LIST_DASHBOARD_PRODUCT_LIBRARY':
    case 'LIST_DASHBOARD_ACTIVE_PRODUCTS':
    case 'LIST_DASHBOARD_MOST_ADDED_PRODUCTS_TO_CART':
    case 'LIST_DASHBOARD_MOST_SOLD_PRODUCTS':
    case 'LIST_DASHBOARD_WAITING_CARTS':
    case 'LIST_DASHBOARD_CUSTOMERS':
    case 'LIST_DASHBOARD_NEW_CUSTOMERS':
    case 'LIST_DASHBOARD_FIRST_TIME_BUYERS':
      return '/apps/dashboard';
    //FINANCE
    case 'VIEW_ACCOUNTING_LIST':
      return '/apps/finance/accounting/list';
    case 'LIST_PAST_EARNINGS':
      return '/apps/finance/earnings/past/list';
    //MARKETING
    case 'VIEW_COUPONS_LIST':
    case 'VIEW_COUPONS_ARCHIVE':
      return '/apps/marketing/coupons/list';
    case 'VIEW_ADVERTISEMENT_LIST':
      return '/apps/marketing/advertisement';
    case 'LIST_SEO_META':
    case 'LIST_SEO_META_ARCHIVE':
      return '/apps/marketing/seo/meta/list';
    case 'LIST_SEO_ON_PAGE':
    case 'LIST_SEO_ON_PAGE_ARCHIVE':
      return '/apps/marketing/seo/on-page/list';
    //ORDERS
    case 'VIEW_ACTIVE_ORDERS_LIST':
      return '/apps/orders/active';
    case 'VIEW_NEW_ORDERS_LIST':
      return '/apps/orders/new';
    case 'VIEW_IN_PROGRESS_ORDERS_LIST':
      return '/apps/orders/in-progress';
    case 'VIEW_RETURN_ORDERS_LIST':
      return '/apps/orders/return';
    case 'VIEW_CANCELED_ORDERS_LIST':
      return '/apps/orders/cancelled';
    case 'VIEW_COMPLETED_ORDERS_LIST':
      return '/apps/orders/completed';
    case 'VIEW_SUCCESSFUL_ORDERS_LIST':
      return '/apps/orders/payment-transferred';
    case 'VIEW_ABORTED_ORDERS_LIST':
      return '/apps/orders/aborted';
    case 'VIEW_INCOMPLETE_ORDERS_LIST':
      return '/apps/orders/incomplete';
    //SETTINGS
    case 'VIEW_CONFIG':
      return '/apps/settings/config/list';
    case 'VIEW_CURRENCIES':
      return '/apps/settings/currencies/list';
    case 'VIEW_SHIPPING_COMPANIES_LIST':
    case 'VIEW_SHIPPING_COMPANIES_ARCHIVE':
      return '/apps/settings/shipping-companies/list';
    case 'VIEW_ACTIVITY_LOG':
      return '/apps/settings/activity-log/list';
    case 'VIEW_CLEAR_CACHED_DATA':
      return '/';
  }
};
