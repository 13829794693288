import axiosServices, { responseSuccess, responseError } from 'utils/axios';

export function listNotifications(i18n: string, setData: (data: any) => void, data: any[], page: number) {
  return axiosServices
    .get(`notifications?clear_new=true&is_api=true&page=${page ?? 1}`, {
      headers: { 'Accept-Language': i18n }
    })
    .then((res) => {
      setData({
        data: [...data, ...res.data.item?.notifications?.data],
        hasMore: res.data?.item?.notifications?.pagination?.more,
        read: res.data.item?.new_count ?? 0
      });
    });
}

export function listNotificationRequests(
  i18n: string,
  page: number,
  offset: number,
  search: string,
  setList: (data: any) => void,
  setPagination: (data: any) => void,
  filters?: any
) {
  return axiosServices
    .get(
      `notification-center/list-data?is_api=true&page=${page}&offset=${offset}${search?.length > 0 ? `&search[value]=${search}` : ''}
    `,
      {
        headers: { 'Accept-Language': i18n }
      }
    )
    .then((res) => {
      setPagination(res.data.items?.meta?.pagination);
      setList(res.data.items?.data);
    });
}

export function getNotification(i18n: string, id: string, setData: (data: any) => void) {
  return axiosServices
    .get(`notification-center/show/${id}`, {
      headers: { 'Accept-Language': i18n }
    })
    .then((res) => {
      setData(res.data.item);
    });
}

export function createNotification(i18n: string, params: any) {
  return axiosServices
    .post('/notification-center/store', params, {
      headers: { 'Accept-Language': i18n }
    })
    .then(
      (res) => {
        if (res.data.code == 200) responseSuccess('notification.success.create', 'marketing');
        else responseError();

        return res.data;
      },
      (err: any) => {
        responseError();
        return err;
      }
    );
}

export function updateNotification(i18n: string, id: string, params: any) {
  return axiosServices
    .post('/notification-center/update/' + id + '?is_api=true', params, {
      headers: { 'Accept-Language': i18n }
    })
    .then(
      (res) => {
        if (res.data.code == 200) responseSuccess('notification.success.update', 'marketing');
        else responseError();

        return res.data;
      },
      (err: any) => {
        responseError();
        return err;
      }
    );
}
