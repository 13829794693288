import axiosServices, { responseError, responseSuccess } from 'utils/axios';

////////////////////////////
// ** super-admin api ** //
//////////////////////////

export function listSuperAdmins(
  page: number,
  offset: number,
  trashed: string,
  search: string,
  filterParams: any,
  setAdmins: (data: any) => void,
  setPagination: (data: any) => void,
  i18n: string
) {
  return axiosServices
    .get(
      `superadmin/users/data/list?is_api=true&page=${page}&offset=${offset}&trashed=${trashed}${search ? `&search[value]=${search}` : ''}${
        filterParams.date_from ? `&date_from=${filterParams.date_from}` : ''
      }${filterParams.date_to ? `&date_to=${filterParams.date_to}` : ''}${
        filterParams.filter_status ? `&filter_status=${filterParams.filter_status}` : ''
      }`,
      { headers: { 'Accept-Language': i18n } }
    )
    .then((res) => {
      setPagination(res.data.item.meta.pagination);
      setAdmins(res.data.item.data);
    });
}

export function deleteSuperAdmin(id: number, i18n: string) {
  return axiosServices.post(`superadmin/users/destroy/${id}?is_api=true`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('admin.success.super_delete_success', 'user_managment');
    },
    (err: any) => {
      responseError('admin.errors.super_delete_error', 'user_managment');
    }
  );
}

export function createSuperAdmin(form: FormData, i18n: string) {
  return axiosServices.post(`superadmin/users/store?is_api=true`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors || (res.data.message && typeof res.data.message != 'string')) {
        responseError('admin.errors.super_create_error', 'user_managment');
        return { errors: res.data.errors ?? res.data.message };
      }
      responseSuccess('admin.success.super_create_success', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError('admin.errors.super_create_error', 'user_managment');
      return err;
    }
  );
}

export function updateSuperAdmin(id: number, form: FormData, i18n: string) {
  return axiosServices
    .post(`superadmin/users/save/personal_information/${id}?is_api=true`, form, { headers: { 'Accept-Language': i18n } })
    .then(
      (res) => {
        if (res.data.errors || (res.data.message && typeof res.data.message != 'string')) {
          responseError('admin.errors.super_update_error', 'user_managment');
          return { errors: res.data.errors ?? res.data.message };
        }
        return { success: true };
      },
      (err: any) => {
        responseError('admin.errors.super_update_error', 'user_managment');
        return err;
      }
    );
}

export function updateSuperAdminStatus(id: number, form: FormData, i18n: string) {
  return axiosServices
    .post(`superadmin/users/save/status-and-permissions/${id}?is_api=true`, form, { headers: { 'Accept-Language': i18n } })
    .then(
      (res) => {
        if (res.data.errors) {
          responseError('admin.errors.super_update_error', 'user_managment');
          return res.data;
        }
        responseSuccess('admin.success.super_update_success', 'user_managment');
        return { success: true };
      },
      (err: any) => {
        responseError('admin.errors.super_update_error', 'user_managment');
        return { errors: err.message };
      }
    );
}

export function restoreSuperAdmin(id: number, i18n: string) {
  return axiosServices.post(`superadmin/users/restore/${id}?is_api=true`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('admin.success.super_restore_success', 'user_managment');
    },
    (err: any) => {
      responseError('admin.errors.super_restore_error', 'user_managment');
    }
  );
}

//////////////////////
// ** admin api ** //
////////////////////

export function listAdmins(
  page: number,
  offset: number,
  trashed: string,
  search: string,
  filterParams: any,
  setAdmins: (data: any) => void,
  setPagination: (data: any) => void,
  i18n: string
) {
  return axiosServices
    .get(
      `admin/users/data/list?is_api=true&page=${page}&offset=${offset}&trashed=${trashed}${search ? `&search[value]=${search}` : ''}${
        filterParams.date_from ? `&date_from=${filterParams.date_from}` : ''
      }${filterParams.date_to ? `&date_to=${filterParams.date_to}` : ''}${
        filterParams.filter_status ? `&filter_status=${filterParams.filter_status}` : ''
      }`,
      { headers: { 'Accept-Language': i18n } }
    )
    .then((res) => {
      setPagination(res.data.item.meta.pagination);
      setAdmins(res.data.item.data);
    });
}

export function deleteAdmin(id: number, i18n: string) {
  return axiosServices.post(`admin/users/destroy/${id}?is_api=true`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('admin.success.delete_success', 'user_managment');
    },
    (err: any) => {
      responseError('admin.errors.delete_error', 'user_managment');
    }
  );
}

export function createAdmin(form: FormData, i18n: string) {
  return axiosServices.post(`admin/users/store?is_api=true`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors || (res.data.message && typeof res.data.message != 'string')) {
        responseError('admin.errors.create_error', 'user_managment');
        return { errors: res.data.errors ?? res.data.message };
      }
      responseSuccess('admin.success.create_success', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError('admin.errors.create_error', 'user_managment');
      return err;
    }
  );
}

export function updateAdmin(id: number, form: FormData, i18n: string) {
  return axiosServices.post(`admin/users/save/personal_information/${id}?is_api=true`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors || (res.data.message && typeof res.data.message != 'string')) {
        responseError('admin.errors.update_error', 'user_managment');
        return { errors: res.data.errors ?? res.data.message };
      }
      return { success: true };
    },
    (err: any) => {
      responseError('admin.errors.update_error', 'user_managment');
      return err;
    }
  );
}

export function updateAdminStatus(id: number, form: FormData, i18n: string) {
  return axiosServices
    .post(`admin/users/save/status-and-permissions/${id}?is_api=true`, form, { headers: { 'Accept-Language': i18n } })
    .then(
      (res) => {
        if (res.data.errors) {
          responseError('admin.errors.update_error', 'user_managment');
          return res.data;
        }
        responseSuccess('admin.success.update_success', 'user_managment');
        return { success: true };
      },
      (err: any) => {
        responseError('admin.errors.update_error', 'user_managment');
        return { errors: err.message };
      }
    );
}

export function restoreAdmin(id: number, i18n: string) {
  return axiosServices.post(`admin/users/restore/${id}?is_api=true`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('admin.success.restore_success', 'user_managment');
    },
    (err: any) => {
      responseError('admin.errors.restore_error', 'user_managment');
    }
  );
}

export function updateProfileData(type: string, id: string, i18n: string, payload: any) {
  return axiosServices
    .post(`/${type}/users/save/personal_information/${id}?is_api=true`, payload, { headers: { 'Accept-Language': i18n } })
    .then(
      (res) => {
        if (res.data.code !== 200 || typeof res.data?.message != 'string') {
          responseError();
          return res.data;
        } else {
          responseSuccess('admin.success.update_success', 'user_managment');
          return res?.data;
        }
      },
      (err: any) => {
        responseError();
        return err;
      }
    );
}

export function updateUserLanguage(form: FormData, i18n: string) {
  return axiosServices.post(`users/change-default-lang?is_api=true`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors) {
        responseError('admin.errors.update_lang_error', 'user_managment');
        return res.data;
      }
      return { success: true };
    },
    (err: any) => {
      responseError('admin.errors.update_lang_error', 'user_managment');
      return { errors: err.message };
    }
  );
}
