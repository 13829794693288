import axiosServices, { responseSuccess, responseError } from 'utils/axios';

export function listSellers(
  page: number,
  trashed: string,
  i18n: string,
  setSellers: (data: any) => void,
  setPagination: (data: any) => void,
  offset: number,
  search: string,
  sort: { sort_by: string | null; sort_type: string | null },
  filterParams: any
) {
  return axiosServices
    .get(
      `seller/data/list?is_api=true&page=${page}&trashed=${trashed}&offset=${offset}
      ${sort?.sort_by ? `&sort_by=${sort?.sort_by}&sort_type=${sort?.sort_type}` : ''}
      ${search ? `&search[value]=${search}` : ''}&custom_search[start]=${
        filterParams.date_from ? filterParams.date_from : ''
      }&custom_search[end]=${filterParams.date_to ? filterParams.date_to : ''}&custom_search[is_professional]=${
        filterParams.is_professional ? filterParams.is_professional : ''
      }&custom_search[has_product]=${filterParams.has_product ? filterParams.has_product : ''}&filter_status=${
        filterParams.filter_status ? filterParams.filter_status : ''
      }`,
      {
        headers: { 'Accept-Language': i18n }
      }
    )
    .then((res) => {
      setPagination(res.data.item.meta.pagination);
      setSellers(res.data.item.data);
    });
}

export function getSellerData(i18n: string, id: string | undefined, setData: (data: any) => void, setRenderFlag: (data: any) => void) {
  return axiosServices.get(`seller/${id}?is_api=true`, { headers: { 'Accept-Language': i18n } }).then((res) => {
    setData(res.data.item.user);
    setRenderFlag(true);
  });
}

export function getSellerDropdown(
  i18n: string,
  list: any[],
  setList: (data: { pagination: { more: boolean }; results: any[] }) => void,
  page: number,
  search: string
) {
  return axiosServices
    .get(`/sellers/ajax?page=${page}&is_api=true${search.length > 0 ? '&search=' + search : ''}`, { headers: { 'Accept-Language': i18n } })
    .then((res) => {
      const newData = {
        pagination: res.data.item.pagination,
        results: [...list, ...res.data.item.results]
      };
      setList(newData);
    });
}

export function sellerSecondStep(form: FormData, i18n: string) {
  return axiosServices.post(`seller/second-register-seller-submit`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (!res.data.success) {
        responseError();
        return res.data;
      }
      responseSuccess('seller.success.second_step', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError();
      return err;
    }
  );
}

export function addSeller(form: FormData, i18n: string) {
  return axiosServices.post(`seller/store?is_api=true`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors) {
        return res.data;
      }
      responseSuccess('seller.success.create', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError('seller.errors.create', 'user_managment');
      return err;
    }
  );
}

export function updateSeller(id: string | undefined, form: FormData, i18n: string) {
  return axiosServices.post(`seller/save/${id}?is_api=true`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.errors) {
        return res.data;
      }
      responseSuccess('seller.success.update', 'user_managment');
      return { success: true };
    },
    (err: any) => {
      responseError('seller.errors.update', 'user_managment');
      return err;
    }
  );
}

export function updateSellerStatus(id: number, status: string, i18n: string) {
  return axiosServices.post(`seller/change-status/${id}/${status}?is_api=true`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('seller.success.update', 'user_managment');
    },
    (err: any) => {
      responseError('seller.errors.update', 'user_managment');
    }
  );
}

export function deleteSeller(id: number, i18n: string) {
  return axiosServices.post(`admin/users/destroy/${id}?is_api=true`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      if (res.data.success) responseSuccess(res.data.message);
      else responseError(res.data.message);
    },
    (err: any) => {
      responseError(err.message);
    }
  );
}

export function restoreSeller(id: number, i18n: string) {
  return axiosServices.post(`admin/users/restore/${id}?is_api=true`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('seller.success.restore', 'user_managment');
    },
    (err: any) => {
      responseError('seller.errors.restore', 'user_managment');
    }
  );
}

export function loginById(form: FormData, i18n: string) {
  return axiosServices.post(`seller/login-by-id?is_api=true`, form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('seller.success.login', 'user_managment');
      return res;
    },
    (err: any) => {
      responseError('seller.errors.login', 'user_managment');
      return err;
    }
  );
}

export function sellerAddNote(id: number, note: string, i18n: string) {
  return axiosServices.post(`seller/save-notes?is_api=true&seller_id=${id}&notes=${note}`, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('seller.success.note', 'user_managment');
    },
    (err: any) => {
      responseError('seller.errors.note', 'user_managment');
    }
  );
}
