import { useEffect, useState } from 'react';

// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';

import Loader from 'components/Loader';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { CompleteInfoDialog } from 'sections/dashboard/CompleteInfoDialog';

//Firebase Notification
import { toast, ToastContainer } from 'react-toastify';
import Message from 'components/Message';
import 'react-toastify/dist/ReactToastify.css';
import { onMessage } from 'firebase/messaging';
import { messaging } from 'lib/firebaseConfig';
import { hotjar } from 'react-hotjar';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV == 'production' && process.env.HJID && process.env.HJSV) {
      hotjar.initialize(parseInt(process.env.HJID), parseInt(process.env.HJSV));
    }
  }, []);

  const onMessageListener = async () => {
    const messagingResolve = await messaging;
    if (messagingResolve)
      onMessage(messagingResolve, (payload) => {
        toast(<Message notification={payload?.data} />);
      });
  };

  onMessageListener();

  if (loading) return <Loader />;

  return (
    <ThemeCustomization>
      {/* <RTLLayout> */}
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <>
              <Notistack>
                <Routes />
                <CompleteInfoDialog />
                <Snackbar />
                <ToastContainer />
              </Notistack>
            </>
          </AuthProvider>
        </ScrollTop>
      </Locales>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  );
};

export default App;
