import React, { createContext, useEffect, useReducer } from 'react';

// reducer - state management
import { LOGIN, LOGOUT, SKIP_INFO_COMPLETE } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { AuthProps, JWTContextType } from 'types/auth';
import { setUser } from 'store/reducers/profile';
import useConfig from 'hooks/useConfig';
import { getFirebaseToken } from 'lib/firebaseConfig';

// api calls
import { adminLogin, forgotPassword, getUserData, resetPassword, sellerRegister, setDeviceToken } from 'services/auth';
import { loginById } from 'services/seller';
import { useDispatch } from 'react-redux';
import { isNil } from 'lodash';

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  showInfoComplete: false
};

const setSession = (serviceToken?: string | null, sessionType: string = 'login') => {
  if (serviceToken) {
    if (sessionType == 'login') localStorage.setItem('serviceToken', serviceToken);
    else localStorage.setItem('signupToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('signupToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const dispatchUser = useDispatch();
  const { i18n } = useConfig();
  const { onChangeLocalization } = useConfig();

  const deviceTokenHandler = async () => {
    const firebaseToken = window.localStorage.getItem('firebaseToken');
    if (firebaseToken) {
      attachDeviceToken(firebaseToken);
    } else {
      const newFirebaseToken = await getFirebaseToken();
      if (newFirebaseToken) {
        localStorage.setItem('firebaseToken', newFirebaseToken);
        attachDeviceToken(newFirebaseToken);
      }
    }
  };

  const attachDeviceToken = (firebaseToken: string) => {
    setDeviceToken({ token: firebaseToken, device: 'web' }, i18n);
  };

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken) {
          const user: any = await getUserData(i18n, serviceToken);
          if (!user || user.error || !user?.item) {
            return logout();
          }

          onChangeLocalization(user?.item?.lang);
          await setSession(serviceToken);
          dispatchUser(setUser(user.item ?? {}));
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: user?.item ?? {}
            }
          });
          //in case we dont't want to set device token whenever we refresh the app we can delete this code
          deviceTokenHandler();
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (email: string, password: string) => {
    let form = new FormData();
    form.append('email', email);
    form.append('password', password);

    const response = await adminLogin(form, i18n);
    const res = response.data.item;
    const user: any = await getUserData(i18n, res?.token);
    const serviceToken = res?.token;
    onChangeLocalization(user?.item?.lang);

    await setSession(serviceToken);
    dispatchUser(setUser(user?.item ?? {}));
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: user?.item,
        showInfoComplete: isNil(user?.country_id) && user?.type == 'SELLER'
      }
    });
    deviceTokenHandler();
  };

  const loginAsSeller = async (id: string) => {
    let form = new FormData();
    form.append('user_id', id);

    const response = await loginById(form, i18n);

    const user = response.data.item;
    const serviceToken = response.data.item.token;

    setSession(serviceToken);
    dispatchUser(setUser(user));
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
    window.location.replace('/apps/dashboard');
  };

  const register = async (form: any) => {
    const response = await sellerRegister(form, i18n);
    const user = response.data?.item;
    setSession(response.data?.item.token, 'signup');
    return { success: !!user };
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const skipCompleteInfo = () => {
    dispatch({
      type: SKIP_INFO_COMPLETE,
      payload: {
        isLoggedIn: true,
        showInfoComplete: false
      }
    });
  };

  const resetPasswordHandler = async (email: string, token: string, password: string, confirmPassword: string) => {
    let form = new FormData();
    form.append('email', email);
    form.append('token', token);
    form.append('password', password);
    form.append('password_confirmation', confirmPassword);
    let resp = await resetPassword(form, i18n);
    return resp;
  };

  const forgotPasswordHandler = async (email: string) => {
    let form = new FormData();
    form.append('email', email);
    await forgotPassword(form, i18n);
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        skipCompleteInfo,
        loginAsSeller,
        logout,
        register,
        resetPasswordHandler,
        forgotPasswordHandler,
        updateProfile
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
