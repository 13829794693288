import axiosServices, { responseError, responseSuccess } from 'utils/axios';

export function adminLogin(form: FormData, i18n: string) {
  return axiosServices.post('auth/login?is_api=true', form, { headers: { 'Accept-Language': i18n } });
}

export function getUserData(i18n: string, token: string) {
  return axiosServices
    .get('auth/get-user-data?is_api=true', { headers: { 'Accept-Language': i18n, Authorization: 'Bearer ' + token } })
    .then(
      (res) => {
        return res.data;
      },
      (err: any) => {
        return err;
      }
    );
}

export function forgotPassword(form: FormData, i18n: string) {
  return axiosServices.post('forgot-password/email?is_api=true', form, { headers: { 'Accept-Language': i18n } });
}
export function resetPassword(form: FormData, i18n: string) {
  return axiosServices.post('forgot-password/reset?is_api=true', form, { headers: { 'Accept-Language': i18n } });
}

export function sellerRegister(form: FormData, i18n: string) {
  return axiosServices.post('auth/register', form, { headers: { 'Accept-Language': i18n } });
}

export function sellerVerificationCode(form: FormData, i18n: string) {
  return axiosServices.post('seller/verificationCode?is_api=false', form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      return { success: true };
    },
    (err: any) => {
      return err;
    }
  );
}

export function sellerResendVerificationCode(form: FormData, i18n: string) {
  return axiosServices.post('seller/resendVerification', form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      responseSuccess('success.code', 'auth');
      return { success: true };
    },
    (err: any) => {
      return err;
    }
  );
}

export const getTermsAndConditions = (i18n: string, setData: (value: string) => void) => {
  return axiosServices
    .get('/get-register-terms-conditions?is_api=true', { headers: { 'Accept-Language': i18n } })
    .then((res) => {
      if (res.data.code != 200) responseError();
      else setData(res.data.item);
      return res.data;
    })
    .catch((err) => {
      responseError();
      return err;
    });
};

export function setDeviceToken(form: { token: string; device: string }, i18n: string) {
  return axiosServices.post('user/device-token', form, { headers: { 'Accept-Language': i18n } }).then(
    (res) => {
      return { success: true };
    },
    (err: any) => {
      return err;
    }
  );
}
