const Message = ({ notification }: { notification: any }) => {
  return (
    <>
      <div id="notificationHeader">
        {notification?.image && (
          <div id="imageContainer">
            <img alt="notification_iamge" src={notification?.image} width={100} />
          </div>
        )}
        <span>{notification?.title}</span>
      </div>
      <div id="notificationBody">{notification?.body}</div>
    </>
  );
};

export default Message;
